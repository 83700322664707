'use strict'
import settings from "@/settings";

const showErrorsMixins = {
    methods: {
        showError(err) {
            if (!err) return false
            if (settings.isDevMode) console.log(err)

            let errorName = err.response?.data?.name ? err.response.data.name + ':' : ''
            let errorMessage = err.response?.data?.message ?? err ?? 'Unknown error'

            if (err.name === 'AxiosError' && !settings.isDevMode) {
                errorName = err.name
                errorMessage = err.message ?? 'Unknown error'
            }

            // Database Exception?
            if (this.$toast) {
                if (!settings.isDevMode) {
                    const isDatabaseException = errorName.includes('Database Exception')
                    if (isDatabaseException) {
                        const numberOutOfRange = errorMessage?.includes('Numeric value out of range')
                        if (numberOutOfRange) {
                            this.$toast.add({severity:'error', summary: this.$t('Error'), detail: 'Numeric value out of range', life: settings.toastLife})
                        } else {
                            this.$toast.add({severity:'error', summary: this.$t('Error'), detail: 'Database Exception', life: settings.toastLife})
                        }
                    } else {
                        const isServerConnectionError = errorMessage.includes('Server connection error')
                        const isRequestAbortedError = errorMessage.includes('Request aborted')
                        if (!isServerConnectionError && !isRequestAbortedError) {
                            this.$toast.add({severity:'error', summary: `${errorName}`, detail: `${errorMessage}`, life: settings.toastLife})
                        }
                    }
                } else {
                    this.$toast.add({severity:'error', summary: `${errorName}`, detail: `${errorMessage}`, life: settings.toastLife})
                }
                // this.$toast.add({position: 'bottom-center',severity:'error', summary: `${errorName}`, detail: `${errorMessage}`, life: settings.toastLife})
            }
            // console.log(`${errorName} ${errorMessage}`)
        },
        // showFinancialPeriodIsClosedError() {
        //     this.$toast.add({severity:'warn', detail: 'Financial period already closed', life: this.settings.toastLife});
        // },
        showUnknownError() {
            this.$toast.add({severity:'error', summary: this.$t('Error'), detail: this.$t('Unknown error'), life: settings.toastLife})
        },
        showFileNotFoundError() {
            this.$toast.add({severity:'error', summary: `404`, detail: 'File not found', life: settings.toastLife})
        }
    }
}

export default showErrorsMixins
