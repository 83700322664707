'use strict'
import settings from "@/settings";

const documentTitleMixins = {
    watch: {
        '$i18n.locale'() {
            this.changeTitle()
        },
    },
    methods: {
        changeTitle() {
            const appName = settings.appName
            if (this.$route.meta.title && appName) {
                document.title = this.$t(this.$route.meta.title) + ' | ' + appName
            } else if (appName) {
                document.title = appName
            }
            // document.title = this.$t('Login') + ' | ' + appName
        },
    }
}

export default documentTitleMixins
