const isDevMode = 0

let settings = {
    isDevMode,
    // developmentMode: true,
    // toastLife: 1750,
    toastLife: 2750,
    defaultLanguage: 'et',
    // appName: 'VirtualGarage.app',
    appName: 'CARVEX OÜ',
    host: isDevMode ? 'http://localhost:8081/' : 'https://vg.carvex.ee/',
    apiEndPoint: isDevMode ? 'http://localhost:8080/' : 'https://vg-api.carvex.ee/',
    socketHost: isDevMode ? 'ws://localhost:5001' : 'wss://vg.carvex.ee/ws/',
    imapAttachmentFolder: 'uploads/mail_attachments/',
    orderFilesFolder: 'uploads/order_files/',
    pathToAvatarsDir: 'uploads/avatars/',
    pathToCarLogosDir: 'uploads/car_makes/',
    measurements: {
        power: 'kW',
        mileage: 'km'
    },
    maxMessageSize: 15000000, //Bites 26214400 - server settings
    dateFormat: 'dd.mm.yy',
    useOfferRegistrationLink: true,
    pathToOffer: 'o/',
    useSmsService: true,
    minTimeToSendSms: { hours: 10, minutes: 0 },
    maxTimeToSendSms: { hours: 21, minutes: 0 },
    autoLogoutTimeout: 45 * 60 * 1000, //45min milliseconds
    phoneCodes: {
        default: '372',
        priority: ['371','358']
    },
    languages: [
        {
            value: 'en',
            code: 'en-GB',
            label: 'English',
            flagCode: 'gb',
        },
        {
            value: 'et',
            code: 'ee-EE',
            label: 'Eesti',
            flagCode: 'ee',
            isDefault: true
        },
        {
            value: 'ru',
            code: 'ru-RU',
            label: 'Русский',
            flagCode: 'ru',
        },
    ],
    useSmartAccounts: true,
    exportPurchaseInvoiceByDefault: true,
    exportSalesInvoiceByDefault: true,
    smartAccountsSettings: {
        defaultPurchaseInvoiceCodes: {
            product: '40511',
            service: '40512',
            consumable: '40515',
        },
        defaultSalesInvoiceCodes: {
            product: '30121',
            service: '30131',
            consumable: '30120',
        }
    },
}

// if (isDevMode) {
//     settings = {
//         ...settings,
//         host: 'http://localhost:8081/',
//         apiEndPoint: 'http://localhost:8080/',
//         socketHost: 'ws://localhost:5001'
//     }
// }
export default settings
