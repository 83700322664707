<template >
  <Toast/>
	<div class="login-body login-background" @click="onDocumentClick">
		<div class="body-container">

      <div class="header">
        <Logo/>
        <div>
          <LoginLanguageSelector
            @languageMenubuttonClick="onLanguageMenuButtonClick"
            :languageMenuActive="languageMenuActive"/>
        </div>
      </div>

      <div class="login-wrapper" :class="{'login-wrapper--tablet' : $store.state.tabletLayout, 'login-wrapper--mobile' : $store.state.mobileLayout}">
        <form class="login-container card">
          <h1>{{ $t('Welcome') }}</h1>
          <p>{{  $t('Sign in to start your session') }}</p>
          <div class="p-grid p-fluid login-fieldset">
            <div class="p-col-12">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                    <i class="pi pi-user"></i>
                </span>
                <InputText :disabled="dataIsSending" :class="{'p-invalid' : submitted && !formData.login}" v-model.trim="formData.login" autocomplete="off" :placeholder="$t('Email')" required="true"/>
              </div>
              <div class="input-error-wrapper">
                <small class="p-invalid" v-if="submitted && !formData.login">{{ $t('Required field') }}</small>
              </div>
<!--              <div class="p-float-label p-input-icon-right">
                <i class="pi pi-user" />
                <InputText id="email" autocomplete="off"/>
                <label for="email">{{ $t('Email') }}</label>
              </div>-->
            </div>

            <div class="p-col-12">
              <div class="p-inputgroup">
                <span class="p-inputgroup-addon">
                    <i class="pi pi-lock"></i>
                </span>
                <InputText :disabled="dataIsSending" :class="{'p-invalid' : submitted && !formData.password}" v-model.trim="formData.password" type="password" autocomplete="off" :placeholder="$t('Password')" required="true"/>
              </div>
              <div class="input-error-wrapper">
                <small class="p-invalid" v-if="submitted && !formData.password">{{ $t('Required field') }}</small>
              </div>
<!--              <div class="p-float-label p-input-icon-right">-->
<!--                <i class="pi pi-lock" />-->
<!--                <InputText id="email" autocomplete="off"/>-->
<!--                <label for="email">{{ $t('Password') }}</label>-->
<!--              </div>-->
            </div>


<!--            <div class="p-col-12 p-mb-3">-->
<!--              <Button :label="!dataIsSending ? $t('Sign In') : $t('Loading') + '...'"-->
<!--                      style="width: auto;"-->
<!--                      :icon="!dataIsSending ? 'pi pi-check' : null"-->
<!--                      :disabled="dataIsSending"-->
<!--                      @click="login"/>-->
<!--            </div>-->

            <div class="p-col-12 p-mb-1">
              <div class="p-d-flex p-ai-center p-jc-center" style="height: 40px;">
                <Button v-if="!dataIsSending" :label="$t('Sign In')"
                        style="width: auto;"
                        icon="pi pi-check"
                        :disabled="dataIsSending"
                        @click="login"/>
                <Spinner v-else :style="'width: 35px; height: 35px'"/>
              </div>
            </div>

<!--            <div class="p-col-6 password-container">-->
<!--              <router-link to="/forgot-password" class="p-link">{{ $t('Forgot Password?') }}</router-link>-->
<!--              <router-link to="#" class="p-link">{{ $t('Forgot Password?') }}</router-link>-->
<!--            </div>-->

<!--            <div class="p-col-12">-->
<!--              <hr>-->
<!--              {{ $t('New on our platform?') }}-->
<!--              <span>-->
<!--                <router-link to="/register" class="p-link">{{ $t('Create an account') }}</router-link>-->
<!--              </span>-->
<!--            </div>-->
          </div>

          <hr>
          <div class="login-container-copyright">©{{ computeYear }}
<!--            <b><a href="https://vgsoft.eu/" target="_blank"> VirtualGarage.app</a></b>.-->
<!--            <b> CARVEX OÜ</b>.-->
            CARVEX OÜ.
            {{ $t('All Rights Reserved') }}.
          </div>
        </form>
      </div>


<!--      <div class="body-container__footer">-->
<!--        © {{ computeYear }} <b><a href="https://virtualgarage.app/" target="_blank">VirtualGarage.app</a></b>. {{ $t('All Rights Reserved') }}-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import LoginLanguageSelector from '@/components/LoginLanguageSelector'
import authService from '@/services/auth.service'
// import httpMixins from "@/mixins/httpMixins";
import settings from "@/settings";
import showErrorsMixins from "@/mixins/showErrorsMixins";
import Logo from "@/components/Logo";
import documentTitleMixins from "@/mixins/titleMixins";

export default {
  mixins: [ showErrorsMixins, documentTitleMixins ],
  components: { LoginLanguageSelector, Logo },
  data() {
    return {
      dataIsSending: false,
      submitted: false,
      formData: {
        login: null,
        password: null,
      },
      languageMenuActive: false,
      languageMenuButtonClick: false
    }
  },
  mounted() {
    if (localStorage.getItem('lang')) {
      this.$i18n.locale = localStorage.getItem('lang')
    }

    // if (this.$i18n.locale === settings.defaultLanguage) {
      this.changeTitle()
    // }
    window.addEventListener('resize', this.checkWindowSize)
  },
  unmounted() {
    window.removeEventListener('resize', this.checkWindowSize)
  },
  methods: {
    checkWindowSize() {
      if (window.innerWidth <= 1024) {
        this.$store.commit('toggleTabletLayout', true)
        if (window.innerWidth <= 640) {
          this.$store.commit('toggleMobileLayout', true)
        } else {
          this.$store.commit('toggleMobileLayout', false)
        }
      } else {
        this.$store.commit('toggleTabletLayout', false)
        this.$store.commit('toggleMobileLayout', false)
      }
    },
    onLanguageMenuButtonClick() {
      this.languageMenuButtonClick = true
      this.languageMenuActive = !this.languageMenuActive
    },
    onDocumentClick() {
      if (!this.languageMenuButtonClick) {
        this.languageMenuActive = false
      }

      this.languageMenuButtonClick = false
    },
    goDashboard(){
      this.$router.push({ path: '/' });
    },
    async login() {
      this.dataIsSending = true
      this.submitted = true

      if (!this.formData.login || !this.formData.password) {
        this.dataIsSending = false
        return false
      }

      const { success, err, errors } = await authService.login(this.formData)
      if (success) {
        // console.log('success')
        this.$router.push('/')
      } else {
        this.submitted = false
        this.dataIsSending = false
        if (errors?.password?.[0] === 'Incorrect login or password.') {
          this.$toast.add({severity:'error', summary: this.$t('Error'), detail: this.$t('Incorrect login or password'), life: settings.toastLife});
        } else {
          this.showError(err)
        }
      }
    }
  },
  computed: {
    computeYear() {
      return new Date().getFullYear()
    }
  },
  // mounted() {
  //   localStorage.setItem('theme', 'light-blue')
  //   console.log(localStorage.getItem('theme'))
  //   EventBus.emit('on-schema', 'light');
  // }
}
</script>

<style scoped lang="scss">
.body-container {
  //display: flex;
  //flex-flow: column;
  //align-items: center;
  //justify-content: space-between;
  //height: 100vh;
  .header {
    margin: 2vh auto;
    width: 90vw;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .login-wrapper {
    margin-top: 12vh;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    .login-container {
      width: auto;
      max-width: 470px;
      text-align: center;
      padding: 30px 20px;
    }
  }
  .login-wrapper--tablet {
    margin-top: 8vh;
  }
  .login-wrapper--mobile {
    margin-top: 4vh;
  }

  .body-container__footer {
    width: 100%;
    padding: 10px;
    font-size: 0.9em;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.15);
    color: #fff;
  }
}

.login-container-copyright {
  margin-top: 20px;
  font-size: 0.85em;
}

.input-error-wrapper {
  text-align: left;
}
//.errors {
//  margin: 12px auto 0;
//  //padding: 2px 0;
//  //width: 300px;
//  //background-color: transparentize(red, 0.5);
//  //color: white;
//  color: orangered;
//}
//
//.errors-message {
//  padding: 6px 0;
//}
//
//.login-btn:disabled {
//  background-color: red;
//}

</style>
