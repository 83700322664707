<template>
  <Toast/>
  <Spinner class="spinner" v-if="!offerHashIsChecked && !offerExistsIsChecked"></Spinner>
  <div v-else-if="!offerExists" class="login-body offer-login-background" @click="onDocumentClick">
    <div class="body-container">
      <div class="header">
        <Logo/>
        <div>
          <LoginLanguageSelector
              @languageMenubuttonClick="onLanguageMenuButtonClick"
              :languageMenuActive="languageMenuActive"/>
        </div>
      </div>

      <div class="not-found-block">
        <div><b>404</b></div>
        <div><b>{{ $t('Not found') }}</b></div>
      </div>
    </div>
  </div>

  <div v-else-if="showLoginCard && !offerData" class="login-body offer-login-background" @click="onDocumentClick">
    <div class="body-container">
      <div class="header">
        <!--        <div class="p-d-flex p-jc-between p-ai-center">-->
        <!--          <img class="logo" src="assets/layout/images/logo_small.png" />-->
        <!--          <span class="logo-text">VirtualGarage</span>-->
        <!--        </div>-->
        <Logo/>
        <div>
          <LoginLanguageSelector
              @languageMenubuttonClick="onLanguageMenuButtonClick"
              :languageMenuActive="languageMenuActive"/>
        </div>
      </div>


<!--      <Spinner class="spinner" v-if="!offerHashIsChecked"></Spinner>-->

<!--      <div v-else-if="offerHashIsChecked" class="login-wrapper">-->
      <div class="login-wrapper">
        <div class="login-container card">
          <h3>{{ $t('Login title') }}</h3>
          <p>{{ $t('Login subtitle') }}</p>
          <div class="p-grid p-fluid login-fieldset">
<!--            <Spinner :isSmall="true" :spinnerStyle="'margin:13px auto;height: 81px'" v-if="offerDataIsLoading"></Spinner>-->
            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-pb-4 p-pt-4">
              <div>
                <InputMask mask="9 9 9 9"
                           @focus="pinIsWrong = false; pinIsEmpty= false;"
                           :placeholder="$t('PIN')"
                           :class="{'p-invalid' : pinIsEmpty || (submitted && pinIsWrong)}"
                           class="offer_access_pin_input"
                           v-model="pin"
                           autocomplete="off"/>
              </div>
              <small class="p-invalid" v-if="pinIsEmpty">{{ $t('Required field') }}</small>
              <small class="p-invalid" v-else-if="submitted && pinIsWrong">{{ $t('PIN is wrong') }}</small>
            </div>


            <div class="p-col-12 p-sm-12 p-md-12 p-lg-12">
              <Button :label="$t('Sign In')"
                      style="width: auto;"
                      icon="pi pi-check"
                      class="p-button-lg login-button"
                      :disabled="loginButtonIsDisabled"
                      @click="loginButtonOnClick"/>
            </div>
          </div>
        </div>
      </div>

<!--      <div class="body-container__footer">-->
<!--        © {{ computeYear }} <b><a href="https://virtualgarage.app/" target="_blank">VirtualGarage.app</a></b>. {{ $t('All Rights Reserved') }}-->
<!--      </div>-->
    </div>
  </div>


<!--  <spinner v-else-if="!offerData && offerDataIsLoading && offerExists"></spinner>-->
  <CustomerOfferData v-else-if="offerData"
                     @change-offer-state="changeOfferState"
                     :nonBookingDays="nonBookingDays"
                     :useAppointmentBooking="useAppointmentBooking"
                     :bookingHours="bookingHours"
                     :workingHours="workingHours"
                     :bookingStep="bookingStep"
                     :appointmentFirstDate="appointmentFirstDate"
                     :appointmentLastDate="appointmentLastDate"
                     :offerData="offerData"/>
</template>

<script>
import LoginLanguageSelector from '@/components/LoginLanguageSelector'
import httpMixins from "@/mixins/httpMixins";
import httpClient from "@/services/http.services";
// import Spinner from "@/components/Spinner";
import CustomerOfferData from "@/pages/price_request/CustomerOfferData";
import settings from "@/settings";
import Logo from "@/components/Logo";

export default {
  mixins: [ httpMixins ],
  components: { LoginLanguageSelector, CustomerOfferData, Logo },
  data() {
    return {
      // offerId: null
      showLoginCard: false,
      offerDataIsLoading: false,
      maxPinStorageDays: 14,
      pinIsWrong: false,
      pinIsEmpty: false,
      offerData: null,
      // loggedIn: false,
      offerHashIsChecked: false,
      offerExists: false,
      offerExistsIsChecked: false,
      loginButtonIsDisabled: false,
      pin: null,
      pinWithRemovedWhitespaces: null,
      submitted: false,
      languageMenuActive: false,
      languageMenuButtonClick: false,
      nonBookingDays: [],
      bookingHours: {},
      workingHours: {},
      bookingStep: 15,
      appointmentFirstDate: 2,
      appointmentLastDate: 60,
      useAppointmentBooking: false,
      // testingData: {
      //   pin: 4635,
      //   hash: '6a16af5'
      // }
    }
  },
  watch: {
    '$route'() {
      this.changeTitle()
      const route = this.$route.path.split('/')
      // if (this.$route.path === '/login' || route.indexOf('o') === -1 || route.indexOf(this.$route.params.hash) === -1) {
      if (this.$route.path === '/login' || route.indexOf('o') === -1) {
        return false
      }

      // console.log(this.$route.path)

      this.offerData = null

      // if (!this.customerData) {
      //   if (this.$route.params.id) {
      //     this.getCustomerData()
      //   }
      // } else {
      //   if (this.$route.params.id && +this.$route.params?.id !== this.customerData.id) {
      //     this.getCustomerData()
      //   }
      // }

      this.checkOfferExists()

    },
    '$i18n.locale'() {
      this.changeTitle()
      this.translatePrimevueComponents()
    },
  },
  mounted() {
    const storageLang = localStorage.getItem('lang')
    if (storageLang) {
      this.$i18n.locale = storageLang
    }
    
    this.checkOfferExists()

    if (this.testingData) {
      this.getOfferData()
    }

    // this.changeTitle()
    this.checkWindowSize()
    if (this.$i18n.locale === settings.defaultLanguage) {
      this.changeTitle()
    }

    this.translatePrimevueComponents()

    window.addEventListener('resize', this.checkWindowSize)
    window.addEventListener('keydown', this.closeOnEscape)
  },
  unmounted() {
    window.removeEventListener('resize', this.checkWindowSize)
    window.removeEventListener('keydown', this.closeOnEscape)
  },
  methods: {
    changeTitle() {
      const appName = settings.appName
      const defaultTitle = appName
      if (this.$route.meta.title) {
        document.title = this.$t(this.$route.meta.title) + ' | ' + appName
      } else {
        document.title = defaultTitle
      }
    },
    // pinOnInput(event) {
    //   if (event.target.value?.length > 4)  {
    //     event.preventDefault()
    //     return false
    //   } else {
    //     // this.pin1 = event.target.value
    //   }
    //   // if (this.pin1?.length > 4) {
    //   //   event.preventDefault()
    //   //   return false
    //   // }
    // },
    changeOfferState(orderStartDate) {
      this.offerData.order_start_date = orderStartDate
      // this.offerData.childOrder.start = orderStartDate
      this.offerData.state = 5
    },
    // changeTitle() {
    //   const defaultTitle = 'VirtualGarage.app'
    //   if (this.$route.meta.title) {
    //     document.title = this.$t(this.$route.meta.title) + ' | VirtualGarage.app'
    //   } else {
    //     document.title = defaultTitle
    //   }
    // },
    translatePrimevueComponents() {
      if (this.$i18n) {
        this.$primevue.config.locale.today = this.$t('Today');
        this.$primevue.config.locale.clear = this.$t('Clear');
        this.$primevue.config.locale.emptyFilterMessage = this.$t('No results found');
        this.$primevue.config.locale.emptyMessage = this.$t('No available options');

        this.$primevue.config.locale.firstDayOfWeek = 1;

        this.$primevue.config.locale.dayNamesMin = [this.$t('Su'), this.$t('Mo'), this.$t('Tu'), this.$t('We'), this.$t('Th'), this.$t('Fr'), this.$t('Sa')]
        // this.$primevue.config.locale.monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        // this.$primevue.config.locale.monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      }
    },
    onLanguageMenuButtonClick() {
      this.languageMenuButtonClick = true
      this.languageMenuActive = !this.languageMenuActive
    },
    onDocumentClick() {
      if (!this.languageMenuButtonClick) {
        this.languageMenuActive = false
      }

      this.languageMenuButtonClick = false
    },
    checkWindowSize() {
      if (window.innerWidth <= 1024) {
        this.$store.commit('toggleTabletLayout', true)
        if (window.innerWidth <= 640) {
          this.$store.commit('toggleMobileLayout', true)
        } else {
          this.$store.commit('toggleMobileLayout', false)
        }
      } else {
        this.$store.commit('toggleTabletLayout', false)
        this.$store.commit('toggleMobileLayout', false)
      }
      // this.logoIsSmall = window.innerWidth <= 740
    },
    closeOnEscape() {
      if (event.which !== 27) return
      // if (this.$store.state.firstLayerIsOpened &&
      //     !this.$store.state.secondLayerIsOpened &&
      //     !this.$store.state.thirdLayerIsOpened) {
      //   if (this.$store.state.firstLayerIsOpened === true) {
      //     this.$store.commit('toggleFirstLayer', false)
      //   }
      // }
      this.$store.commit('closeRequiredAppLayer')
    },
    async checkOfferExists() {
      this.offerHashIsChecked = false

      const offerHash = this.$route.params.hash
      if (!offerHash) {
        this.offerExists = false
        this.offerHashIsChecked = true
        return false
      }
      try {
        const { status, data } = await httpClient.post('site/check-offer-exists', { hash: offerHash })
        if (status === 200 && data?.success) {
          //then delete all these 3 strokes exclude 'this.checkLocalStorage()'
          if (data.pin_is_empty) {
            this.getOfferData(null, true, true)
          } else {
            this.checkLocalStorage()
          }

          // let languageChanged = false
          let language = false
          // if (localStorage.getItem('langIsChanged')) {
          //   languageChanged = localStorage.getItem('langIsChanged')
          // }

          if (localStorage.getItem('lang')) {
            language = localStorage.getItem('lang')
          }

          // if (data.user_lang && (!languageChanged || !language)) {
          if (data.user_lang && !language) {
            this.changeLanguage(data.user_lang)
          }

          if (!data.link_opened_at) {
            this.markOfferLinkOpened(offerHash)
          }

          this.offerExists = true

        } else {
          this.offerExists = false
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.offerHashIsChecked = true
        this.offerExistsIsChecked = true
      }
    },
    checkLocalStorage() {
      const localStoragePin = localStorage.getItem(this.$route.params.hash + '_pin')
      const pin = localStoragePin ? window.atob(localStoragePin) : null
      const pinDate = +localStorage.getItem(this.$route.params.hash + '_date')
      let pinDateIsValid = false
      if (pinDate) {
        const maxPinStorageDateInMinutes = this.maxPinStorageDays * 24 * 60
        const now = Date.now()
        const minutesDifference = (now - pinDate) / 1000 / 60

        if (minutesDifference < maxPinStorageDateInMinutes) {
          pinDateIsValid = true
        } else {
          localStorage.removeItem(this.$route.params.hash + '_date')
          localStorage.removeItem(this.$route.params.hash + '_pin')
        }
      }
      if (pin && pinDateIsValid) {
        this.showLoginCard = false
        this.getOfferData(pin)
      } else {
        this.showLoginCard = true
      }
    },
    loginButtonOnClick() {
      this.loginButtonIsDisabled = true
      this.submitted = true

      let pin
      if (this.pin) {
        pin = this.pin.replace(/\s/g, '');
      }

      if (!pin?.length) {
        this.loginButtonIsDisabled = false
        this.pinIsEmpty = true
        return false
      } else {
        this.pinIsEmpty = false
      }

      this.getOfferData(pin, true)
    },
    async getOfferData(pin, saveLocalStorageData = false, pin_is_empty = false) {
      this.offerDataIsLoading = true
      let obj = {}
      if (this.testingData) {
        obj = {
          pin: 4635,
          hash: '6a16af5'
        }
      } else {
        obj = {
          pin: pin,
          hash: this.$route.params.hash,
          pin_is_empty
        }
      }

      try {
        // const { status, data } = await httpClient.post('site/get-offer-data?expand=positions,positions.tax,consumable,car,carMake,carModel,childOrder', obj)
        const { status, data } = await httpClient.post('site/get-offer-data?expand=positions,positions.tax,consumable,car,childOrder', obj)
        if (status === 200 && data?.success) {
          window.scrollTo(0, 0)
          if (data.offer_data) {
            this.offerData = data.offer_data
            if (data.offer_data.childOrder?.start) {
              this.offerData.order_start_date = this.offerData.childOrder.start
            }
          }

          if (data.branch_currency && data.branch_locale && data.branch_timezone) {
            this.updateBranchData(data.branch_currency, data.branch_locale, data.branch_timezone)
          }

          if (data.non_booking_days) {
            this.nonBookingDays = JSON.parse(data.non_booking_days)
          }

          if (data.appointment_booking_hours) {
            this.bookingHours = JSON.parse(data.appointment_booking_hours)
          }

          if (data.working_hours) {
            this.workingHours = JSON.parse(data.working_hours)
          }

          this.appointmentFirstDate = data.appointment_first_date ?? 2
          this.appointmentLastDate = data.appointment_last_date ?? 60
          this.bookingStep = data.appointment_booking_step ?? 15
          this.useAppointmentBooking = !!data.use_appointment_booking

          if (saveLocalStorageData) {
            // let localStorageSecretPin =
            if (pin) {
              localStorage.setItem(this.$route.params.hash + '_pin', window.btoa(pin))
            }
            localStorage.setItem(this.$route.params.hash + '_date', Date.now())
          }
        } else if (data?.pin_is_wrong) {
          this.$toast.add({severity:'error', summary: this.$t('Error'), detail: this.$t('PIN is wrong'), life: settings.toastLife})
          this.pinIsWrong = true
        } else {
          this.$toast.add({severity:'error', summary: this.$t('Error'), detail: this.$t('Unknown error'), life: settings.toastLife})
        }
      } catch(err) {
        const errorMessage = err?.message ?? 'Unknown error'
        this.$toast.add({severity:'error', summary: this.$t('Error'), detail: errorMessage, life: settings.toastLife});
        // this.showError(err)
      } finally {
        this.loginButtonIsDisabled = false
        this.offerDataIsLoading = false
        this.showLoginCard = true
        // this.submitted = false
      }
    },
    updateBranchData(currency, locale, timezone) {
      let branchData = {
        currency,
        locale,
        timezone
      }
      this.$store.commit('updateBranchData', branchData)
    },
    async markOfferLinkOpened(offerHash) {
      try {
        await httpClient.post('site/mark-offer-link-opened', { hash: offerHash })
      } catch(err) {
        this.showError(err)
      }
    },
    changeLanguage(value) {
      if (!value) return

      this.$i18n.locale = value
      localStorage.setItem('lang', value)
      // localStorage.setItem('langIsChanged', true)
    }
  },
  computed: {
    computeYear() {
      return new Date().getFullYear()
    },
  },

}
</script>

<style scoped lang="scss">
.spinner {
  //margin: 0 auto;
  //position: relative;
  height: 100vh;
  //margin-top: 25vh;
}

.body-container {
  .not-found-block {
    margin: 17vh auto 0 auto;
    text-align: center;
    //margin-left: 20px 0;
    font-size: 3em;
    line-height: 1.5;
    color: #fff;
  }
  //display: flex;
  //flex-flow: column;
  //align-items: center;
  //justify-content: space-between;
  //height: 100vh;
  .header {
    margin: 2vh auto;
    width: 90vw;
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .login-wrapper {
    margin-top: 10vh;
    width: auto;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    .login-container {
      width: auto;
      max-width: 380px;
      text-align: center;
      padding: 30px 20px;
    }
    .offer_access_pin_input {
      max-width: 140px;
      height: 65px;
      font-size: 2em;
      font-weight: 300;
      text-align: center;
    }
  }

  .body-container__footer {
    width: 100%;
    padding: 10px;
    font-size: 0.9em;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.15);
    color: #fff;
  }
}

.login-container-copyright {
  margin-top: 20px;
  font-size: 0.85em;
}

.input-error-wrapper {
  text-align: left;
}
//.errors {
//  margin: 12px auto 0;
//  //padding: 2px 0;
//  //width: 300px;
//  //background-color: transparentize(red, 0.5);
//  //color: white;
//  color: orangered;
//}
//
//.errors-message {
//  padding: 6px 0;
//}
//
//.login-btn:disabled {
//  background-color: red;
//}

</style>