<template>
  <Modal :visible="visible"
         :style="computedWindowSize"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div class="p-dialog-title">Changing customer status</div>
    </template>
    <Spinner v-if="modalDataIsLoading"></Spinner>
    <div v-else class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12">
        <LimitedCharsTextarea :submitted="submitted"
                              :rows="2"
                              v-model="comment"
                              :maxChars="commentMaxChars"
                              :id="'customer-status-comment' + customerId"
                              :label="'Comment'"/>
<!--        <small class="p-invalid" v-if="submitted && !reason">{{ $t('Required field') }}</small>-->
      </div>
    </div>
    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :disabled="modalDataIsLoading" :label="$t('Save')" icon="pi pi-check" class="p-button-text" @click="changeCustomerStatus"/>
    </template>
  </Modal>
</template>

<script>
import httpClient from "@/services/http.services";
import settings from "@/settings";
import showErrorsMixins from "@/mixins/showErrorsMixins";

export default {
  mixins: [ showErrorsMixins ],
  name: 'ChangeCustomerStatusModal',
  emits: ['close', 'change-customer-status'],
  props: {
  //   data: String,
  //   header: null,
  //   footer: null,
    order: Object,
    visible: Boolean,
    customerId: Number,
    newStatus: Number,
    lastComment: String,
  //   style: Object,
  },
  watch: {
    // lastComment(value) {
    //   handler() {
    //     console.log(value)
    //     this.checkAllItemsAreSelected()
    //     this.toggleInvoicesSidebar()
    //   },
    //   deep: true
    // },
    // lastComment: {
    //   handler(value) {
    //     if (!value) return false
    //     console.log(value)
    //     this.comment = value
    //   },
    //   deep: true
    // },
    visible() {
      if (!this.visible) {
        this.modalDataIsLoading = false
        this.submitted = false
        this.comment = null
      }
    },
  },
  data() {
    return {
      modalDataIsLoading: false,
      commentMaxChars: 500,
      submitted: false,
      comment: null
    }
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async changeCustomerStatus() {
      this.submitted = true
      // if (!this.reason) {
      //   return false
      // }
      if (this.comment && this.comment.length > this.commentMaxChars) {
        return false
      }

      this.modalDataIsLoading = true

      const obj = {
        userId: this.customerId ?? null,
        newStatus: this.newStatus ?? null,
        comment: this.comment ?? null,
      }

      try {
        const { status, data } = await httpClient.post('user/change-customer-status', obj)
        if (status === 200 && data?.success) {
          this.$toast.add({severity:'success', detail: this.$t('Data updated'), life: settings.toastLife});
          this.$emit('change-customer-status', this.comment)
          this.close()
        } else if (data?.error) {
          this.$toast.add({severity:'error', summary: data.error.summary, detail: data.error.detail, life: settings.toastLife});
        } else {
          this.showUnknownError()
        }
      } catch(err) {
        this.showError(err)
      } finally {
        this.modalDataIsLoading = false
      }
      // this.$emit('update-items')
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'relative', top: '-10vh', width: '85%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'relative', top: '-10vh', width: '450px', marginTop: '25px'}
      } else {
        return {position: 'relative', top: '-10vh', width: '450px', marginTop: '50px'}
      }
    }
  }
}
</script>