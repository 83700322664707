'use strict'
import constants from "@/constants";

const superAdmin = constants.userRoles.superAdmin
const admin = constants.userRoles.admin
const moderator = constants.userRoles.moderator
const accountant = constants.userRoles.accountant
const workshopMaster = constants.userRoles.employeeWorkshopMaster
const master = constants.userRoles.employeeMaster

export default [
    {
        index: 1,
        label: {
            en: 'Favorites',
            et: 'Lemmikud',
            ru: 'Избранное'
        },
        icon: 'ti-home',
        roles: [superAdmin, admin, moderator, accountant, workshopMaster, master],
        subMenuItems: [
            {
                label: {
                    en: 'Dashboard',
                    et: 'Armatuurlaud',
                    ru: 'Приборная панель'
                },
                to:'/',
                roles: [superAdmin, admin, moderator, accountant, workshopMaster, master],
            }
        ],
    },
    {
        index: 2,
        label: {
            en: 'Orders',
            et: 'Tellimused',
            ru: 'Заказы'
        },
        icon: 'ti-hummer',
        roles: [superAdmin, admin, moderator, accountant, workshopMaster, master],
        subMenuItems: [
            {
                label: {
                    en: 'Orders',
                    et: 'Tellimused',
                    ru: 'Заказы'
                },
                to:'/orders',
                roles: [superAdmin, admin, moderator, accountant],
            },
            {
                label: {
                    en: 'Orders archive',
                    et: 'Tellimuste arhiiv',
                    ru: 'Архив заказов'
                },
                to:'/orders-archive',
                roles: [superAdmin, admin, moderator, accountant],
            },
            {
                label: {
                    en: 'Orders',
                    et: 'Tellimused',
                    ru: 'Заказы'
                },
                to:'/master-orders',
                roles: [workshopMaster, master],
            },
            // {
            //     label: {
            //         en: 'Orders archive',
            //         et: 'Tellimuste arhiiv',
            //         ru: 'Архив заказов'
            //     },
            //     to:'/employee-orders-archive',
            //     roles: [workshopMaster, master],
            // },
            // {
            //     label: {
            //         en: 'Offers',
            //         et: 'Pakkumised',
            //         ru: 'Ценовые предложения'
            //     },
            //     to:'/offers',
            //     roles: [superAdmin, admin, moderator],
            // },
        ]
    },
    {
        index: 3,
        label: {
            en: 'Offers',
            et: 'Pakkumised',
            ru: 'Предложения'
        },
        icon: 'ti-clipboard',
        roles: [superAdmin, admin, moderator, accountant],
        subMenuItems: [
            {
                label: {
                    en: 'Offers',
                    et: 'Pakkumised',
                    ru: 'Предложения'
                },
                to:'/offers',
                roles: [superAdmin, admin, moderator, accountant],
            },
        ]
    },
    {
        index: 4,
        label: {
            en: 'Warehouse',
            et: 'Ladu',
            ru: 'Склад'
        },
        icon: 'ti-shopping-cart',
        // icon: 'ti-package',
        roles: [superAdmin, admin, moderator, accountant],
        subMenuItems: [
            {
                label: {
                    en: 'Warehouse items',
                    et: 'Laokaubad',
                    ru: 'Складские товары'
                },
                to:'/warehouse-items',
                roles: [superAdmin, admin, moderator, accountant],
            },
            {
                label: {
                    en: 'Requests',
                    et: 'Taotlused',
                    ru: 'Запросы'
                },
                to:'/requests',
                roles: [superAdmin, admin, moderator, accountant],
            },
            {
                label: {
                    en: 'Returns',
                    et: 'Tagastused',
                    ru: 'Возвраты'
                },
                to:'/returns',
                roles: [superAdmin, admin, moderator, accountant],
            },
            {
                label: {
                    en: 'Warehouse request',
                    et: 'Lao taotlused',
                    ru: 'Запросы на склад'
                },
                to:'/warehouse-requests',
                roles: [superAdmin, admin, accountant],
            }
        ]
    },
    {
        index: 5,
        label: {
            en: 'Users',
            et: 'Kasutajad',
            ru: 'Пользователи'
        },
        icon: 'ti-user',
        roles: [superAdmin, admin, accountant],
        subMenuItems: [
            {
                label: {
                    en: 'Customers',
                    et: 'Kliendid',
                    ru: 'Клиенты'
                },
                to:'/customers',
                roles: [superAdmin, admin, accountant],
            },
            {
                label: {
                    en: 'Employees',
                    et: 'Töötajad',
                    ru: 'Сотрудники'
                },
                to:'/employees',
                roles: [superAdmin, admin, accountant],
            },
        ]
    },
    {
        index: 5,
        label: {
            en: 'Cars',
            et: 'Autod',
            ru: 'Автомобили'
        },
        icon: 'ti-car',
        roles: [superAdmin, admin, moderator, accountant],
        subMenuItems: [
            {
                label: {
                    en: 'Cars',
                    et: 'Autod',
                    ru: 'Автомобили'
                },
                to:'/cars',
                roles: [superAdmin, admin, moderator, accountant],
            }
        ]
    },
    {
        index: 6,
        label: {
            en: 'Finance',
            et: 'Rahandus',
            ru: 'Финансы'
        },
        // icon: 'ti-money',
        icon: 'ti-wallet',
        roles: [superAdmin, admin, accountant, moderator, workshopMaster, master],
        subMenuItems: [
            {
                label: {
                    en: 'Purchase invoices',
                    et: 'Ostuarved',
                    ru: 'Счета покупок'
                },
                to:'/purchase-invoices',
                roles: [superAdmin, admin, accountant],
            },
            {
                label: {
                    en: 'Sales invoices',
                    et: 'Müügiarved',
                    ru: 'Счета продаж'
                },
                to:'/sales-invoices',
                roles: [superAdmin, admin, accountant],
            },
            {
                label: {
                    en: 'Сashbox transactions',
                    et: 'Kassa tehingud',
                    ru: 'Кассовые операции'
                },
                to:'/cashbox-transactions',
                roles: [superAdmin, admin, accountant, moderator],
            },
            {
                label: {
                    en: 'Card payments',
                    et: 'Kaardimaksed',
                    ru: 'Платежи картой'
                },
                to:'/card-payments',
                roles: [superAdmin, admin, accountant],
            },
            {
                label: {
                    en: 'Bank payments',
                    et: 'Panga maksed',
                    ru: 'Банковские платежи'
                },
                to:'/bank-payments',
                roles: [superAdmin, admin, accountant],
            },
            {
                label: {
                    en: 'Payments',
                    et: 'Maksed',
                    ru: 'Платежи'
                },
                to:'/employee-payments',
                roles: [workshopMaster, master],
            },
            // {
            //     label: {
            //         en: 'Test',
            //         et: 'Test',
            //         ru: 'Test'
            //     },
            //     to:'/test',
            //     roles: [workshopMaster, master],
            // },
        ]
    },
    {
        index: 7,
        label: {
            en: 'Data directory',
            et: 'Andmekataloog',
            ru: 'Справочники'
        },
        icon: 'ti-write',
        roles: [superAdmin, admin, moderator, accountant],
        subMenuItems: [
            {
                label: {
                    en: 'Suppliers',
                    et: 'Hankijad',
                    ru: 'Поставщики'
                },
                to:'/suppliers',
                roles: [superAdmin, admin, accountant],
            },
            {
                label: {
                    en: 'Taxes',
                    et: 'Maksud',
                    ru: 'Налоги'
                },
                to:'/taxes',
                roles: [superAdmin, admin, accountant],
            },
            {
                label: {
                    en: 'Working areas',
                    et: 'Tööpiirkonnad',
                    ru: 'Рабочие зоны'
                },
                to:'/areas',
                roles: [superAdmin, admin],
            },
            {
                label: {
                    en: 'Сashboxes',
                    et: 'Rahakastid',
                    ru: 'Кассы'
                },
                to:'/cashboxes',
                roles: [superAdmin, admin],
            },
            {
                label: {
                    en: 'Services',
                    et: 'Teenused',
                    ru: 'Услуги'
                },
                to:'/services',
                roles: [superAdmin, admin, accountant],
            },
            {
                label: {
                    en: 'Warehouses',
                    et: 'Laod',
                    ru: 'Склады'
                },
                to:'/warehouses',
                roles: [superAdmin, admin],
            },
            {
                label: {
                    en: 'Links settings',
                    et: 'Linkide seaded',
                    ru: 'Настройки cсылок'
                },
                to:'/links-settings',
                roles: [superAdmin, admin],
            },
            {
                label: {
                    en: 'Links',
                    et: 'Lingid',
                    ru: 'Ссылки'
                },
                to:'/links',
                roles: [moderator, accountant],
            },
        ]
    },
    {
        index: 8,
        label: {
            en: 'Settings',
            et: 'Seaded',
            ru: 'Настройки'
        },
        icon: 'ti-settings',
        roles: [superAdmin,admin],
        subMenuItems: [
            {
                label: {
                    en: 'General',
                    et: 'Üldseaded',
                    ru: 'Общие'
                },
                to:'/settings',
                roles: [superAdmin,admin],
            },
            {
                label: {
                    en: 'Email accounts',
                    et: 'E-posti kontod',
                    ru: 'Почтовые аккаунты'
                },
                to:'/email-accounts',
                roles: [superAdmin],
            },
            {
                label: {
                    en: 'Notifications',
                    et: 'Notifications',
                    ru: 'Notifications'
                },
                to:'/notifications',
                roles: [superAdmin],
            }
        ]
    },
]