<template>
  <Button :label="label" icon="pi pi-plus" class="p-button-raised"/>
</template>

<script>
export default {
  name: 'AddNewButton',
  props: {
    label: String
  }
}
</script>

<style scoped>
/*.action-button {*/
/*  margin: 0 3px;*/
/*}*/
</style>