<template>
<!--  {{ layoutMode }}-->
	<AppSubmenu
      class="layout-menu"
      :class="{ 'padding-bottom' : layoutMode === 'static' || layoutMode === 'overlay' }"
      :items="model"
      :layoutMode="layoutMode"
      :menuActive="active"
      :root="true"
      :parentMenuItemActive="true"/>
</template>

<script>
import AppSubmenu from './AppSubmenu';

export default {
	props: {
		model: Array,
		layoutMode: String,
		active: Boolean,
	},
	components: {
		'AppSubmenu': AppSubmenu
	},
}
</script>

<style scoped>
.padding-bottom {
  padding-bottom: 140px !important;
}
</style>
