export default {
    state () {
        return {
            user: {
                role: null,
                cashboxes: [],
                emailAccounts: [],
                schema: 'light'
            },
            logoutTimestampIsOverdue: true,
            dropdownOverlayVisible: false,
            firstLayerIsOpened: false,
            secondLayerIsOpened: false,
            thirdLayerIsOpened: false,
            fourthLayerIsOpened: false,
            tabletLayout: false,
            mobileLayout: false,
            layoutMode: 'horizontal',
            branchData: {},
            newOffersCount: 0,
            unansweredEmailsCount: 0,
            webSocket: null,
            // openedOrdersIds: new Set(),
            updatedOrderId: null,
            updateOrdersPage: false,
            updatedOfferId: null,
            updateOffersPage: false,
            logoutCountdownModal: false,
            // orderEditingId: null,
            // showSearchResultPanel
        }
    },
    mutations: {
        changeAppSchema(state, value) {
            state.user.schema = value
        },
        changeUserData(state, value) {
            state.user = value
        },
        changeUserRole(state, value) {
            state.user.role = value
        },
        toggleDropdownOverlayVisibility(state, value) {
            // console.log('Second Layer ' + (value ? 'opened' : 'closed'))
            state.dropdownOverlayVisible = value
        },
        toggleFirstLayer(state, value) {
            // console.log('First Layer ' + (value ? 'opened' : 'closed'))
            state.firstLayerIsOpened = value
            if (!value) state.dropdownOverlayVisible = false
        },
        toggleSecondLayer(state, value) {
            // console.log('Second Layer ' + (value ? 'opened' : 'closed'))
            state.secondLayerIsOpened = value
            if (!value) state.dropdownOverlayVisible = false
        },
        toggleThirdLayer(state, value) {
            // console.log('Third Layer ' + (value ? 'opened' : 'closed'))
            state.thirdLayerIsOpened = value
            if (!value) state.dropdownOverlayVisible = false
        },
        toggleFourthLayer(state, value) {
            // console.log('Third Layer ' + (value ? 'opened' : 'closed'))
            state.fourthLayerIsOpened = value
            if (!value) state.dropdownOverlayVisible = false
        },
        closeRequiredAppLayer(state) {
            // console.log('Third Layer ' + (value ? 'opened' : 'closed'))
            // state.fourthLayerIsOpened = value
            if (state.dropdownOverlayVisible) {
                state.dropdownOverlayVisible = false
                return false
            }

            if (state.fourthLayerIsOpened) {
                state.fourthLayerIsOpened = false
            } else if (state.thirdLayerIsOpened) {
                state.thirdLayerIsOpened = false
            } else if (state.secondLayerIsOpened) {
                state.secondLayerIsOpened = false
            } else if (state.firstLayerIsOpened) {
                state.firstLayerIsOpened = false
            }
        },
        openRequiredAppLayer(state) {
            state.dropdownOverlayVisible = false

            if (!state.firstLayerIsOpened) {
                state.firstLayerIsOpened = true
            } else if (!state.secondLayerIsOpened) {
                state.secondLayerIsOpened = true
            } else if (!state.thirdLayerIsOpened) {
                state.thirdLayerIsOpened = true
            } else if (!state.fourthLayerIsOpened) {
                state.fourthLayerIsOpened = true
            }
            // if (!value) state.dropdownOverlayVisible = false
        },
        toggleTabletLayout(state, value) {
            state.tabletLayout = value
        },
        toggleMobileLayout(state, value) {
            state.mobileLayout = value
        },
        changeLayoutMode(state, value) {
            state.layoutMode = value
        },
        updateBranchData(state, branchData) {
            state.branchData = branchData
        },
        updateUserCashboxes(state, value) {
            state.user.cashboxes = value
            // console.log(value)
        },
        updateNewOffersCount(state, value) {
            if (!value) value = 0
            state.newOffersCount = value
        },
        updateUnansweredEmailsCount(state, value) {
            if (!value) value = 0
            state.unansweredEmailsCount = value
        },
        updateUserEmailAccounts(state, value) {
            if (!value) value = 0
            state.user.emailAccounts = value
            let unansweredEmailsCount = 0
            if (value.length) {
                value.filter(a => a.show_messages)?.forEach(emailAccount => {
                    unansweredEmailsCount += +emailAccount.accountData.unansweredCount
                })
            }

            state.unansweredEmailsCount = unansweredEmailsCount
            // console.log(value)
        },
        createWebSocket(state, value) {
            state.webSocket = value
        },
        // changeOpenedOrdersIds(state, value) {
        //     if (!state.openedOrdersIds.has(value)) {
        //         state.openedOrdersIds.add(value)
        //     }
        // },
        changeUpdatedOrderId(state, value) {
            state.updatedOrderId = value
        },
        changeUpdatedOfferId(state, value) {
            state.updatedOfferId = value
        },
        updateOrdersPage(state, value) {
            state.updateOrdersPage = value
        },
        updateOffersPage(state, value) {
            state.updateOffersPage = value
        },
        showLogoutCountdownModal(state, value) {
            state.logoutCountdownModal = value
        },
        toggleLogoutTimestampIsOverdue(state, value) {
            state.logoutTimestampIsOverdue = value
        },
        // updateBranchDataFinancialPeriodDueDate(state, value) {
        //     state.branchData.financial_period_closed_due = value
        // },
        updateBranchDataVariable(state, data) {
            const variableName = data.variableName
            const value = data.value
            // if (!variable || (!value && value !== 0)) return
            if (!variableName) return
            state.branchData[variableName] = value
        },
    },
    strict: process.env.NODE_ENV !== 'production'
}