'use strict'
import settings from "@/settings";
import SmsMessages from "@/translations/SmsMessages";

const generateMixins = {
    methods: {
        generateSendNotificationAtTime(orderStartDate) {
            if (!orderStartDate) {
                return false
            }
            // const orderStartDate = new Date(startDate)

            const now = new Date()
            const nowYear = now.getFullYear()
            const nowMonth = now.getMonth()
            const nowDay = now.getDate()

            const orderStartYear = orderStartDate.getFullYear()
            const orderStartMonth = orderStartDate.getMonth()
            const orderStartDay = orderStartDate.getDate()
            let orderStartHours = orderStartDate.getHours()
            let orderStartMinutes = orderStartDate.getMinutes()

            if (orderStartYear < nowYear ||
                orderStartYear === nowYear && orderStartMonth < nowMonth ||
                orderStartYear === nowYear && orderStartMonth === nowMonth && orderStartDay <= nowDay) {
                this.sendNotificationAt = null
                this.sendNotificationDue = null
            } else {
                if (orderStartHours < settings.minTimeToSendSms.hours) {
                    orderStartHours = settings.minTimeToSendSms.hours
                    orderStartMinutes = 0
                } else if (orderStartHours > settings.maxTimeToSendSms.hours) {
                    orderStartHours = settings.maxTimeToSendSms.hours
                    orderStartMinutes = 0
                }
                this.sendNotificationAt = new Date(orderStartYear, orderStartMonth, orderStartDay - 1, orderStartHours, orderStartMinutes)
                this.sendNotificationDue = new Date(orderStartYear, orderStartMonth, orderStartDay - 1, settings.maxTimeToSendSms.hours, settings.maxTimeToSendSms.minutes)
            }
        },
        generateSmsNotificationText(orderStartDate) {
            // const orderStartDate = this.itemData.start
            // const orderStartDate = startDate
            // console.log(orderStartDate)
            // return false
            if (!orderStartDate) return false


            // let customerLang = this.selectedCustomer ? (this.selectedCustomer.language || settings.defaultLanguage) : settings.defaultLanguage
            let customerLang = this.$i18n.locale || settings.defaultLanguage

            if (this.selectedCustomer) {
                customerLang = this.selectedCustomer.language || settings.defaultLanguage
            }

            const notification_sms_text = SmsMessages.appointmentReminder[customerLang].text
            if (notification_sms_text) {
                const orderStartYear = orderStartDate.getFullYear()
                let orderStartMonth = orderStartDate.getMonth()
                let orderStartDay = orderStartDate.getDate()

                let hours = orderStartDate.getHours()
                let minutes = orderStartDate.getMinutes()

                const dateTimeStamp = +new Date(orderStartYear, orderStartMonth, orderStartDay, hours, minutes)
                const date = this.formatDay(dateTimeStamp / 1000)
                const time = this.formatTime(dateTimeStamp / 1000)

                const searchDate = '{date}'
                const searchTime = '{time}'
                const replaceDateWith = date
                const replaceTimeWith = time

                let result = notification_sms_text.split(searchDate).join(replaceDateWith).split(searchTime).join(replaceTimeWith)

                this.notificationSmsText = result
            }
            // console.log(this.notificationSmsText)
            // console.log('Send at: ' + new Date(this.sendNotificationAt) + '. Send due: ' + new Date(this.sendNotificationDue))
        },
        generateRawCode(value) {
            if (!value) return null
            const rawCode = value.replace(/[^a-zA-Z0-9]/g, '')
            return rawCode
            // const code = value
            // let rawCodeArray = []
            // const charArray = code.split('')
            // for (let i = 0; i < charArray.length; i++) {
            //     if (charArray[i] === ',' ||
            //         charArray[i] === '.' ||
            //         charArray[i] === '/' ||
            //         charArray[i] === "\\" ||
            //         charArray[i] === '-' ||
            //         charArray[i] === '_' ||
            //         charArray[i] === '!' ||
            //         charArray[i] === '?' ||
            //         charArray[i] === "'" ||
            //         charArray[i] === '"' ||
            //         charArray[i] === ' ' ||
            //         charArray[i] === '#' ||
            //         charArray[i] === '@' ||
            //         charArray[i] === '$' ||
            //         charArray[i] === '%' ||
            //         charArray[i] === '^' ||
            //         charArray[i] === '>' ||
            //         charArray[i] === '<' ||
            //         charArray[i] === '&' ||
            //         charArray[i] === '±' ||
            //         charArray[i] === '§' ||
            //         charArray[i] === '*' ||
            //         charArray[i] === ':' ||
            //         charArray[i] === ';' ||
            //         charArray[i] === '=' ||
            //         charArray[i] === '(' ||
            //         charArray[i] === ')' ||
            //         charArray[i] === '[' ||
            //         charArray[i] === ']' ||
            //         charArray[i] === '{' ||
            //         charArray[i] === '}'
            //     ) {
            //         continue
            //     } else {
            //         rawCodeArray.push(charArray[i])
            //     }
            // }
            // const rawCode = rawCodeArray.join('')
            // return rawCode.trim()
        },
        removeSpaces(value) {
            if (!value) return null
            const string = value
            let stringArray = []
            const charArray = string.split('')
            for (let i = 0; i < charArray.length; i++) {
                if (charArray[i] === ' ') {
                    continue
                } else {
                    stringArray.push(charArray[i])
                }
            }
            const stringWithoutSpaces = stringArray.join('')
            return stringWithoutSpaces
        },
        // generateDateWithCurrentTime(date) {
        //     const now = new Date()
        //     const year = date.getFullYear()
        //     const month = date.getMonth()
        //     const day = date.getDate()
        //
        //     const hours = now.getHours()
        //     const minutes = now.getMinutes()
        //     const seconds = now.getSeconds()
        //     const ms = now.getMilliseconds()
        //
        //     return new Date(year, month, day, hours, minutes, seconds, ms)
        // },
        // generateDayEndDate(date) {
        //     const year = date.getFullYear()
        //     const month = date.getMonth()
        //     const day = date.getDate()
        //
        //     return new Date(year, month, day +1, 0,0, -1)
        // },
    }
}

export default generateMixins
