import constants from '@/constants'

const superAdmin = constants.userRoles.superAdmin
const admin = constants.userRoles.admin
const moderator = constants.userRoles.moderator
const accountant = constants.userRoles.accountant
// const employeeWorkshopMaster = constants.userRoles.employeeWorkshopMaster
// const employeeMaster = constants.userRoles.employeeMaster

const UsersRouter = [
    {
        path: '/customers',
        name: 'Customers',
        meta: {
            auth: true,
            roles: [superAdmin, admin, accountant],
            title: 'Customers'
        },
        component: () => import('@/pages/users/Customers')
    },
    {
        path: '/customers-archive',
        name: 'CustomersArchive',
        meta: {
            auth: true,
            roles: [superAdmin, admin],
            title: 'Customers archive'
        },
        component: () => import('@/pages/users/CustomersArchive')
    },
    {
        path: '/customers/:id',
        name: 'Customer',
        meta: {
            auth: true,
            roles: [superAdmin, admin, moderator, accountant],
            title: 'Customer'
        },
        redirect: { name: 'CustomerOverview' },
        component: () => import('@/pages/users/Customer'),
        children: [
            {
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                path: 'overview',
                name: 'CustomerOverview',
                component: () => import('@/pages/users/CustomerOverview')
            },
            {
                // UserProfile will be rendered inside User's <router-view>
                // when /user/:id/profile is matched
                path: 'orders',
                name: 'Customer orders',
                component: () => import('@/pages/users/CustomerOrders')
            },
            {
                // UserPosts will be rendered inside User's <router-view>
                // when /user/:id/posts is matched
                path: 'offers',
                name: 'Customer offers',
                component: () => import('@/pages/users/CustomerOffers')
            },
            {
                // UserPosts will be rendered inside User's <router-view>
                // when /user/:id/posts is matched
                path: 'cars',
                name: 'Customer cars',
                component: () => import('@/pages/users/CustomerCars')
            }
        ]
    },
    {
        path: '/employees',
        name: 'Employees',
        meta: {
            auth: true,
            roles: [superAdmin, admin, accountant],
            title: 'Employees'
        },
        component: () => import('@/pages/users/Employees')
    },
    {
        path: '/employees-archive',
        name: 'EmployeesArchive',
        meta: {
            auth: true,
            roles: [superAdmin, admin, accountant],
            title: 'Employees archive'
        },
        component: () => import('@/pages/users/EmployeesArchive')
    },
    {
        path: '/employees/:id',
        name: 'Employee',
        meta: {
            auth: true,
            roles: [superAdmin, admin, accountant],
            title: 'Employee'
        },
        redirect: { name: 'EmployeeOverview' },
        component: () => import('@/pages/users/Employee'),
        children: [
            {
                path: 'overview',
                name: 'EmployeeOverview',
                component: () => import('@/pages/users/EmployeeOverview')
            },
            {
                path: 'salary-settings',
                name: 'EmployeeSalarySettings',
                component: () => import('@/pages/users/EmployeeSalarySettings')
            },
            {
                path: 'payments',
                name: 'EmployeePayments',
                component: () => import('@/pages/users/EmployeePayments')
            },
        ]
    },
    // {
    //     path: '/employee-payments',
    //     name: 'MasterPayments',
    //     meta: {
    //         auth: true,
    //         roles: [superAdmin, admin, moderator, accountant, employeeWorkshopMaster, employeeMaster],
    //         title: 'Payments'
    //     },
    //     component: () => import('@/pages/users/MasterPayments')
    // },
    // {
    //     path: '/test',
    //     name: 'Test',
    //     meta: {
    //         auth: true,
    //         roles: [superAdmin, admin, moderator, accountant, employeeWorkshopMaster, employeeMaster],
    //     },
    //     component: () => import('@/pages/users/Test')
    // },
]

export default UsersRouter