const et = {
  //Login page
  Welcome: 'Tere tulemast',
  'Sign in to start your session': 'Seansi alustamiseks logige sisse',
   Password: 'Salasõna',
  'Repeat password': 'Korda salasõna',
  'Passwords do not match': 'Paroolid ei kattu',
  'Sign In': 'Logi sisse',
  'Forgot Password?': 'Unustasid parooli?',
  'New on our platform?': 'Uus meie platvormil?',
  'Create an account': 'Kontot looma',
  'Incorrect login or password': 'Vale login või parool',

  //Price request form
  'Minimum length': 'Minimaalne pikkus',
  'Maximum length': 'Maksimaalne pikkus',
  'Minimum value': 'Minimaalne väärtus',
  'Price request form': 'Hinnapäringu vorm',
  'Price request': 'Hinnapäring',
  'Vehicle data': 'Sõiduki andmed',
  'Your data': 'Teie andmed',
  'Desired works / parts': 'Soovitud tööd / varuosad',
  'Description': 'Kirjeldus',
  'Characters left': 'Tähemärke jäänud',
  'Workshop parts': 'Töökoja varuosad',
  'Customer parts': 'Kliendi varuosad',
  'privacyPolicyCheckboxText': 'Vajutage siia, kui olete lugenud ja olete nõus',
  'privacyPolicyCheckboxLink': 'privaatsuspoliitikaga',
  'Fields are required': 'Kohustuslikud väljad',
  'Send request': 'Saada päring',
  'priceRequestSentThankyouText': 'Aitäh! Teie päring on kätte saadud.',
  'priceRequestSentWeReceivedText': 'Koostame Teie jaoks esimesel võimalusel hinnapakkumise.',
  'Go back': 'Tagasi',

  //Dashboard
  Dashboard: 'Armatuurlaud',

  //User
  User: 'Kasutaja',
  Users: 'Kasutajad',
  'General information': 'Üldine informatsioon',

  //Customers
  Customers: 'Kliendid',
  Customer: 'Klient',
  'Client card': 'Kliendikaart',
  'Credit limit': 'Krediidilimiit',
  Individual: 'Eraisik',
  Individuals: 'Eraisikud',
  'Private persons': 'Eraisikud',
  Companies: 'Ettevõtted',
  Company: 'Ettevõtte',
  'Company name': 'Ettevõtte nimi',
  'Adding a new customer': 'Uue kliendi lisamine',
  'Customer editing': 'Kliendi muutmine',
  //'Adding a new company': 'Uue ettevõtte lisamine',
  //'Company editing': 'Ettevõtte muutmine',
  'Save password': 'Salvesta parool',
  'Adding a new address': 'Uue aadressi lisamine',
  'Address editing': 'Aadressi muutmine',
  'Days to pay': 'Makseviis',
  'Services discount': 'Teenuste allahindlus',
  'Products discount': 'Kaupade allahindlus',

  //Employees
  Employee: 'Töötaja',
  Employees: 'Töötajad',
  'Employees archive': 'Töötajate arhiiv',
  Position: 'Amet',
  'Adding a new employee': 'Uue töötaja lisamine',
  'Employee editing': 'Töötaja toimetamine',
  'Personal code': 'Isikukood',
  Contract: 'Tööleping',
  Start: 'Algus',
  End: 'Lõpp',
  'Salary settings': 'Palga seaded',
  Payments: 'Maksed',
  Time: 'Aeg',

  //Orders
  Order: 'Tellimus',
  Orders: 'Tellimused',
  Qty: 'Kogus',
  'Purchase price': 'Ostuhind',
  'Selling price': 'Müügihind',
  Buy: 'Ost',
  Sell: 'Müük',
  Discount: 'Allahindlus',
  Returned: 'Tagastatud',
  Reason: 'Põhjus',
  Type: 'Tüüp',
  Dublicate: 'Dubleerida',
  'Create offer': 'Loo pakkumine',
  Restore: 'Taastada',
  'To archive': 'Arhiveerida',
  'Return from archive': 'Tagasta arhiivist',
  'Increase quantity': 'Suurenda kogust',
  'Decrease quantity': 'Vähenda kogust',
  Paid: 'Makstud',

  //Orders archive
  'Orders archive': 'Tellimuste arhiiv',

  //Offers
  Offer: 'Pakkumine',
  Offers: 'Pakkumised',

  //Customer offer registration
  'Not found': 'Ei leitud',
  'PIN': 'PIN',
  'Enter pin': 'Sisestage PIN',
  'PIN is wrong': 'Vale PIN-kood',
  'Login title': 'Pakkumise vaatamiseks sisestage PIN-kood',
  'Login subtitle': 'PIN koodi leiate teile saadetud e-posti või SMSi teel tellimuse linkiga.',
  'Car data': 'Auto andmed',
  'Number': 'Number',
  'Status': 'Olek',
  'Valid until': 'Kehtiv kuni',
  'Booking time': 'Broneerimise aeg',
  'Additional information': 'Lisainformatsioon',
  'Choose booking time': 'Valige broneerimisaeg',
  'Price': 'Hind',
  'Sum': 'Summa',
  'Sum without tax': 'Summa käibemaksuta',
  'Manual tax input': 'Käsitsi käibemaksu sisestamine',
  'Total sum': 'Summa kokku',
  'Appointment booking': 'Aja broneerimine',
  'Day': 'Päev',
  'Working hours': 'Tööaeg',
  'Please select time': 'Palun valige sobiv aeg',
  'Approximate service time': 'Ligikaudne teeninduse aeg',
  'Approximate order end time': 'Ligikaudne tellimuse lõpuaeg',
  'workTakesMoreTimeText': 'Töökoja tööaja lõpuni on jäänud vähem aega Teie tellimuse täitmiseks. Tööde teostamine jätkub järgmisel tööpäeval.',
  'Additional wishes (optional)': 'Lisasoovid (valikuline)',
  'bookingAgreementWithTermsText': 'Olen nõus, et tööde teostamise aeg on liigikaudne, sõltuvalt tellimusse töömahust. Töö käigus võib tekkida vajadus lisatöödeks.',
  'orderCreatedThankYouText': 'Täname! Tellimus on vastu võetud.',

  //Warehouse
  'Warehouse': 'Ladu',
  'Warehouses': 'Ladud',
  'Warehouse items': 'Laokaubad',
  'Warehouse requests': 'Lao taotlused',

  //Links
  Links: 'Lingid',
  'Links settings': 'Linkide seaded',

  //Messages
  'Messages': 'Sõnumid',

  //Cars
  Car: 'Auto',
  Cars: 'Autod',
  'Adding a new car': 'Uue auto lisamine',
  'Car editing': 'Auto toimetamine',
  'Owner': 'Omanik',
  'Make': 'Mark',
  'Model': 'Mudel',
  'Registration number': 'Registreerimismärk',
  'Plate number': 'Registrimärk',
  'VIN number': 'VIN-kood',
  'Engine code': 'Mootori kood',
  'Production year': 'Tootmisaasta',
  'Year': 'Aasta',
  'Drivetrain': 'Vedav sild',
  'Fuel': 'Kütus',
  'Transmission': 'Käigukast',
  'Power': 'Võimsus',
  'kW': 'kW',
  'hp': 'hp',
  'Goods': 'Goods',
  'Mileage': 'Läbisõit',

  //Suppliers
  Supplier: 'Hankija',
  Suppliers: 'Hankijad',
  'Adding a new supplier': 'Uue hankija lisamine',
  'Supplier editing': 'Hankija toimetamine',
  'Supplier name': 'Hankija nimi',
  'Bank name': 'Panga nimi',
  'Bank account': 'Pangakonto',

  //Taxes
  Taxes: 'Maksud',

  //Cashboxes
  Cashbox: 'Rahakast',
  Cashboxes: 'Rahakastid',

  //Sales invoices
  'Sales invoices': 'Müügiarved',
  'Sales invoice': 'Müügiarve',

  //Purchase invoices
  'Purchase invoices': 'Ostuarved',
  'Invoice number already exists': 'Arve number on juba olemas',
  Requests: 'Taotlused',
  Returns: 'Tagastused',

  //Cashbox transactions
  'Cashbox transactions': 'Kassa tehingud',
  'All cashboxes': 'Kõik rahakastid',

  //Bank payments
  'Bank payments': 'Panga maksed',

  //Card payments
  'Card payments': 'Kaardimaksed',

  //Working areas
  'Working areas': 'Tööpiirkonnad',
  'Working area': 'Tööpiirkond',
  'Adding a new working area': 'Uue tööpiirkonna lisamine',
  'Working area editing': 'Tööpiirkonna toimetamine',

  //Services
  Services: 'Teenused',
  'Adding a new service': 'Uue teenuse lisamine',
  'Service editing': 'Teenuse toimetamine',
  'Adding a new service category': 'Uue teenusekategooria lisamine',
  'Service category editing': 'Teenuse kategooria toimetamine',
  Category: 'Kategooria',

  //Settings
  Settings: 'Seaded',

  //Email settings
  'Email settings': 'E-posti seaded',
  'Email accounts': 'E-posti kontod',
  Emails: 'E-kirjad',

  //App Config Component:
  'Input Style': 'Sisendstiil',
  Outlined: 'Esitatud',
  Filled: 'Täidetud',
  'Ripple Effect': 'Ripple Effect',
  'Menu Type': 'Menüü tüüp',
  Static: 'Staatiline',
  Overlay: 'Varjatud',
  Horizontal: 'Horisontaalne',
  Slim: 'Õhuke',
  'Color schema': 'Värvilahendus',
  Light: 'Valgus',
  Dark: 'Tume',
  Dim: 'Dim',
  Themes: 'Teemad',

  //General, common
  Percent: 'Protsent',
  'Login': 'Logi sisse',
  // 'Mo': 'Es',
  // 'Tu': 'Te',
  // 'We': 'Ko',
  // 'Th': 'Ne',
  // 'Fr': 'Re',
  // 'Sa': 'La',
  // 'Su': 'Pü',
  'Mo': 'E',
  'Tu': 'T',
  'We': 'K',
  'Th': 'N',
  'Fr': 'R',
  'Sa': 'L',
  'Su': 'P',
  Date: 'Kuupäev',
  'Today': 'Täna',
  'Yesterday': 'Eile',
  'Tomorrow': 'Homme',
  'Current week': 'Jooksev nädal',
  'Previous week': 'Eelmine nädal',
  'Current month': 'Jooksev kuu',
  'Previous month': 'Eelmine kuu',
  'Custom period': 'Kohandatud periood',
  'Required field': 'Kohustuslik väli',
  'Value too large': 'Väärtus on liiga suur',
  'Value too small': 'Väärtus on liiga väike',
  'Invalid date format': 'Vale kuupäevavorming',
  Name: 'Nimi',
  Code: 'Kood',
  'First name': 'Eesnimi',
  'Last name': 'Perekonnanimi',
  'Full name': 'Täisnimi',
  Email: 'E-post',
  Phone: 'Telefon',
  Role: 'Roll',
  Country: 'Riik',
  'Select': 'Vali',
  'Please select': 'Palun vali',
  Address: 'Aadress',
  City: 'Linn',
  State: 'Maakond',
  Postcode: 'Postiindeks',
  Language: 'Keel',
  'Select language': 'Vali keel',
  Birthdate: 'Sünnipäev',
  Comment: 'Kommentaar',
  'Reg. number': 'Registrikood',
  'VAT number': 'KMKR number',
  Tax: 'Käibemaks',
  Balance: 'Saldo',
  Profit: 'Kasum',
  Created: 'Loodud',
  Add: 'Lisa',
  'Add category': 'Lisa kategooria',
  'All categories': 'Kõik kategooriad',
  Delete: 'Kustuta',
  Search: 'Otsing',
  Page: 'Lehekülg',
  Error: 'Viga',
  'Unknown error': 'Tundmatu viga',
  Counters: 'Loendurid',
  'Total records': 'Kirjeid kokku',
  'User archive': 'Kliendi arhiiv',
  'All Rights Reserved': 'Kõik õigused kaitstud',
  'No data available': 'Andmed puuduvad',
  Edit: 'Muuda',
  Save: 'Salvesta',
  Cancel: 'Tühista',
  Clear: 'Tühista',
  'Data saved': 'Andmed on salvestatud',
  'Data updated': 'Andmed on uuendatud',
  'Data deleted': 'Andmed on kustutatud',
  Successful: 'Edukalt',
  Confirm: 'Kinnitage',
  Close: 'Sulge',
  'Are you sure you want to delete': 'Kas kindlasti soovite kustutada',
  'Are you sure you want to delete selected item?': 'Kas soovite kindlasti valitud üksuse kustutada?',
  'Are you sure you want to delete selected items?': 'Kas soovite kindlasti valitud üksused kustutada?',
  'Are you sure you want to proceed?': 'Kas olete kindel, et soovite jätkata?',
  Yes: 'Jah',
  No: 'Ei',
  Deleted: 'Kustutatud',
  Restored: 'Taastatud',
  h: 't',  //Hour
  hours: 'tunnid',
  km: 'km',
  'Change history': 'Muudatuste ajalugu',
  'No results found': 'Tulemusi ei leitud',
  'No available options': 'Puuduvad valikud',
  'Value is not a valid email address': 'Väärtus pole kehtiv e-posti aadress',
  Undefined: 'Määratlemata',
  'Phone number has already been taken': 'Telefoninumber on juba võetud',
  'Registration number has already been taken': 'Registreerimisnumber on juba võetud',
  'Username has already been taken': 'Kasutajanimi on juba võetud',
  'This page is under construction': 'See leht on valmimisel',
  Maintenance: 'Hooldus',
  Size: 'Suurus',
  Continue: 'Jätka',
  'End session': 'Lõpeta seanss',
  'Create new': 'Loo uus',
  Adjustment: 'Ümardus',
  Debit: 'Deebet',
  Credit: 'Kreedit',
}

export default et
