<template>
  <div class="layout-wrapper layout-overlay" @click="onDocumentClick">
    <div class="header background-theme-color" id="appTopbar">
      <Logo :maxHeight="50"/>
      <div>
        <LoginLanguageSelector
            @languageMenubuttonClick="onLanguageMenuButtonClick"
            :languageMenuActive="languageMenuActive"/>
      </div>
    </div>
    <div class="layout-content">
      <div class="layout-content-container">
        <div class="p-grid">
          <div class="p-col-12">
            <div class="card">
<!--              <Toolbar class="p-mb-4">-->
<!--              <Toolbar class="p-mb-4">-->
<!--                <template #start>-->
<!--                  <div style="font-size: 1.4em; color: inherit">{{ $t('Offer') }}: {{ offerData?.number }}</div>-->
<!--                </template>-->
<!--                <template #end>-->
<!--                  <Button v-if="useAppointmentBooking && offerData?.state === 3 && +offerData.time"-->
<!--                          @click="openAppointmentBookingModal"-->
<!--                          label="Choose appointment time"-->
<!--                          class="p-button-warning p-button-raised"-->
<!--                          icon="ti-time"/>-->
<!--&lt;!&ndash;                  <Button label="Ask a question" class="p-button-secondary p-button-raised" icon="ti-comments"></Button>&ndash;&gt;-->
<!--                </template>-->
<!--              </Toolbar>-->
              <div class="p-grid" v-if="offerData">
<!--                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12">-->
<!--                    <Button v-if="useAppointmentBooking && offerData?.state === 3 && +offerData.time"-->
<!--                            @click="openAppointmentBookingModal"-->
<!--                            label="Choose appointment time"-->
<!--                            class="p-button-warning p-button-raised p-mt-4 p-mb-2"-->
<!--                            icon="ti-time"/>-->
  <!--                  <Button @click="openAppointmentBookingModal" label="Choose appointment time" class="p-button-raised p-mt-2 p-mb-2" icon="ti-time"></Button>-->
<!--                </div>-->

                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-fluid">
                  <Panel :header="$t('General information')">
<!--                  <Panel>-->
<!--                    <template #header>-->
<!--                      <div class="p-p-0" style="font-size: 1.4rem!important;">Offer details</div>-->
<!--                    </template>-->
                    <div class="panel-inner">
                      <table >
                        <tbody style="text-align: left">
                          <tr>
                            <th class="p-p-1">{{ $t('Number') }}:</th>
                            <td class="p-p-1">
                              <div class="p-ml-3 p-d-flex p-ai-center p-flex-wrap">
                                <div class="p-mr-2">{{ offerData.number }}</div>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th class="p-p-1">{{ $t('Status') }}:</th>
                            <td class="p-p-1">
                              <div class="p-ml-3">
                                <OfferStateButton :showHistory="false" :item="offerData"/>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <th class="p-p-1">{{ $t('Created') }}:</th>
                            <td class="p-p-1 p-d-flex p-flex-wrap">
                              <span class="p-ml-3">{{ formatDate(offerData.created_at) }}</span>
                            </td>
                          </tr>
                          <tr v-if="offerData.state !== 5 && offerData.state !== 7">
                            <th class="p-p-1">{{ $t('Valid until') }}:</th>
                            <td class="p-p-1 p-d-flex p-flex-wrap">
                              <span class="p-ml-3"
                                    :class="{'warning-color': countOverdueDays(offerData.valid_until) <= 0 }">
                                {{ formatDay(offerData.valid_until) }}
                              </span>
                            </td>
                          </tr>
<!--                          <tr v-else-if="offerData.order_start_date">-->
                          <tr v-else-if="offerData.order_start_date">
                            <th class="p-p-1">{{ $t('Booking time') }}:</th>
                            <td class="p-p-1 p-d-flex p-flex-wrap">
                              <span class="p-ml-3"><b>{{ formatDate(offerData.order_start_date) }}</b></span>
                            </td>
                          </tr>
  <!--                        <tr>-->
  <!--                          <th class="p-p-1">Customer comment:</th>-->
  <!--                          <td class="p-p-1 p-d-flex p-flex-wrap">-->
  <!--                            <span class="p-ml-3">{{ offerData.customer_comment }}</span>-->
  <!--                          </td>-->
  <!--                        </tr>-->
  <!--                        <tr>-->
  <!--                          <th class="p-p-1">Approximate time:</th>-->
  <!--                          <td class="p-p-1 p-d-flex p-flex-wrap">-->
  <!--                            <span class="p-ml-3">&asymp; {{formatDecimal(offerData.time)}} {{ $t('h') }}.</span>-->
  <!--                          </td>-->
  <!--                        </tr>-->
                        </tbody>
                      </table>
                    </div>
                  </Panel>
                </div>

                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6 p-fluid">
                  <Panel :header="$t('Car data')">
                    <div class="panel-inner">
                      <table>
                        <tbody style="text-align: left">
                          <tr>
                            <th class="p-p-1">{{ $t('Make') }}:</th>
                            <td class="p-p-1 p-d-flex p-flex-wrap">
                              <span class="p-ml-3" v-if="offerData.car && offerData.car.make">{{ offerData.car.make.name }}</span>
                            </td>
                          </tr>
                          <tr>
                            <th class="p-p-1">{{ $t('Model') }}:</th>
                            <td class="p-p-1 p-d-flex p-flex-wrap">
                              <span class="p-ml-3" v-if="offerData.car && offerData.car.model">{{ offerData.car.model.name }}</span>
                            </td>
                          </tr>
                          <tr>
                            <th class="p-p-1">{{ $t('Year') }}:</th>
                            <td class="p-p-1 p-d-flex p-flex-wrap">
                              <span class="p-ml-3" v-if="offerData.car?.year">{{ offerData.car.year }}</span>
                              <i v-else class="ti-minus p-ml-3"></i>
                            </td>
                          </tr>
                          <tr>
                            <th class="p-p-1">{{ $t('Plate number') }}:</th>
                            <td class="p-p-1 p-d-flex p-flex-wrap">
<!--                              <span class="p-ml-3" v-if="offerData.car?.plate_number">*{{ offerData.car.plate_number.slice(1) }}</span>-->
                              <span class="p-ml-3" v-if="offerData.car?.plate_number">{{ offerData.car.plate_number }}</span>
                            </td>
                          </tr>
<!--                            <tr v-if="offerData.car?.vin">-->
<!--                              <th class="p-p-1">VIN number:</th>-->
<!--                              <td class="p-p-1 p-d-flex p-flex-wrap">-->
<!--                                <span class="p-ml-3">{{ offerData.car.vin }}</span>-->
<!--                              </td>-->
<!--                            </tr>-->

                        </tbody>
                      </table>
                    </div>
                  </Panel>
                </div>

                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12" v-if="offerData.additional_information">
                  <div class="comment" v-if="offerData.additional_information" style="padding: 12px 10px; border-radius: 5px">
                    <b>{{ $t('Additional information') }}: </b>
                    <span :class="{'warning-color':offerData.is_warning}">
                      {{ offerData.additional_information }}
                    </span>
                  </div>

<!--                  <div class="comment" v-if="offerData.customer_comment" style="padding: 10px;">-->
<!--                    <b>Customer comment: </b>-->
<!--                    <span>-->
<!--                    {{ offerData.customer_comment }}-->
<!--                  </span>-->
<!--                  </div>-->
                </div>


                <div v-if="useAppointmentBooking && offerData?.state === 3 && +offerData.time" class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-d-flex p-flex-wrap">
                  <div class="p-p-1">
<!--                    <Button v-if="useAppointmentBooking && offerData?.state === 3 && +offerData.time"-->
<!--                            @click="openAppointmentBookingModal"-->
<!--                            label="Choose appointment time"-->
<!--                            class="p-button-secondary p-button-raised"-->
<!--                            icon="ti-time"/>-->
                    <Button
                            @click="openAppointmentBookingModal"
                            :label="$t('Choose booking time')"
                            class="p-button-warning p-button-raised"
                            icon="ti-time"/>
<!--                  </div>-->
<!--                  <div class="p-p-1">-->
<!--                    <Button label="Ask a question"-->
<!--                            class="p-button-secondary p-button-raised"-->
<!--                            icon="ti-comments"/>-->
<!--                    &lt;!&ndash;                          icon="ti-help"/>&ndash;&gt;-->
                  </div>
                </div>
                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12 p-fluid">
                  <CustomerOfferPositionsTable :item="offerData" :isSpoilerTable="false"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      <footer class="layout-footer">-->
<!--        &lt;!&ndash;      <span class="footer-text-left">© {{ getYear }} <b><a href="https://virtualgarage.app/" target="_blank">VirtualGarage.app</a></b>. {{ $t('All Rights Reserved') }}</span>&ndash;&gt;-->
<!--        <span class="footer-text-left">© {{ getYear }} <b>CARVEX OÜ</b>. {{ $t('All Rights Reserved') }}.</span>-->
<!--      </footer>-->
      <AppFooter />
    </div>
  </div>

  <AppointmentBookingModal :visible="appointmentBookingModal && !orderCreatedModal"
                           @close="closeAppointmentBookingModal"
                           @change-offer-state="changeOfferState"
                           :useAppointmentBooking="useAppointmentBooking"
                           :bookingHours="bookingHours"
                           :workingHours="workingHours"
                           :bookingStep="bookingStep"
                           :appointmentFirstDate="appointmentFirstDate"
                           :appointmentLastDate="appointmentLastDate"
                           :offerTime="+offerData.time"
                           :offerId="offerData.id"
                           :offerData="offerData"
                           :nonBookingDays="nonBookingDays"
                           :thereIsActiveServicePosition="thereIsActiveServicePosition">
  </AppointmentBookingModal>

  <OrderCreatedModal :visible="orderCreatedModal" :orderStartDate="orderStartDate" @close="closeOrderCreatedModal"/>
</template>

<script>
import httpMixins from "@/mixins/httpMixins";
import formatMixins from "@/mixins/formatMixins";
import constants from "@/constants";
import CustomerOfferPositionsTable from "@/pages/offers/components/CustomerOfferPositionsTable";
import LoginLanguageSelector from "@/components/LoginLanguageSelector";
import OfferStateButton from "@/pages/offers/components/OfferStateButton";
import calculateMixins from "@/mixins/calculateMixins";
import AppointmentBookingModal from "@/pages/price_request/components/AppointmentBookingModal";
import OrderCreatedModal from "@/pages/price_request/components/OrderCreatedModal";
import AppFooter from "@/AppFooter";
import Logo from "@/components/Logo";

export default {
  mixins: [ httpMixins, formatMixins, calculateMixins ],
  emits: ['change-offer-state'],
  components: { CustomerOfferPositionsTable, LoginLanguageSelector, OfferStateButton, AppointmentBookingModal, OrderCreatedModal, AppFooter,Logo },
  props: {
    offerData: Object,
    useAppointmentBooking: {
      type: Boolean,
      default: false
    },
    nonBookingDays: Array,
    appointmentFirstDate: Number,
    appointmentLastDate: Number,
    bookingHours: Object,
    workingHours: Object,
    bookingStep: Number,
  },
  data() {
    return {
      // thereIsActiveServicePosition: false,
      orderCreatedModal: false,
      orderStartDate: null,
      appointmentBookingModal: false,
      // loggedIn: false,
      languageMenuActive: false,
      languageMenuButtonClick: false,
      constants,
    }
  },
  watch: {
    // '$store.state.firstLayerIsOpened'() {
    //   if (!this.$store.state.firstLayerIsOpened) {
    //     if (this.appointmentBookingModal) {
    //       this.closeAppointmentBookingModal()
    //     }
    //     if (this.orderCreatedModal) {
    //       this.closeOrderCreatedModal()
    //     }
    //   }
    // },
    // offerData(value) {
    //   if (!value) return false
    // },
    '$store.state.firstLayerIsOpened'() {
      const appTopbar = document.getElementById('appTopbar')
      const appTopbarPaddingRight = +parseInt(window.getComputedStyle(appTopbar).paddingRight)
      const scrollWidth = window.innerWidth - document.documentElement.clientWidth

      if (this.$store.state.firstLayerIsOpened) {
        document.documentElement.style.paddingRight = scrollWidth + 'px'
        appTopbar.style.paddingRight = appTopbarPaddingRight + scrollWidth + 'px'
        document.documentElement.style.overflowY = 'hidden'
      } else {
        appTopbar.style.paddingRight = ''
        document.documentElement.style.paddingRight = ''
        document.documentElement.style.overflowY = 'auto'

        if (this.appointmentBookingModal) {
          this.closeAppointmentBookingModal()
        }
        if (this.orderCreatedModal) {
          this.closeOrderCreatedModal()
        }
      }
    }
  },
  methods: {
    changeOfferState(orderStartDate) {
      this.$emit('change-offer-state', orderStartDate)
      this.orderStartDate = orderStartDate
      this.openOrderCreatedModal()
    },
    openOrderCreatedModal() {
      this.orderCreatedModal = true
      this.$store.commit('toggleFirstLayer', true)
    },
    closeOrderCreatedModal() {
      this.orderCreatedModal = false
      this.$store.commit('toggleFirstLayer', false)
    },
    openAppointmentBookingModal() {
      this.appointmentBookingModal = true
      // this.$store.commit('toggleFirstLayer', true)
      this.$store.commit('openRequiredAppLayer')
    },
    closeAppointmentBookingModal() {
      this.appointmentBookingModal = false
      // this.$store.commit('toggleFirstLayer', false)
      this.$store.commit('closeRequiredAppLayer')
    },
    onLanguageMenuButtonClick() {
      this.languageMenuButtonClick = true
      this.languageMenuActive = !this.languageMenuActive
    },
    onDocumentClick() {
      if (!this.languageMenuButtonClick) {
        this.languageMenuActive = false
      }
      this.languageMenuButtonClick = false
    },
  },
  computed: {
    thereIsActiveServicePosition() {
      return !!this.offerData?.positions?.find(pos => pos.status && pos.type === 1)
    },
    getYear() {
      return new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-wrapper {
  height: 50vh!important;
}

.header {
  z-index: 10;
  position: fixed;
  top: 0;
  padding: 8px 16px;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 4px 0px transparentize(#000, 0.7);
}

.panel-inner {
  min-height: 110px;
}

//.layout-content-container {
//  padding-top: 30px!important;
//}

.layout-content {
  padding-top:54px!important;
}

  //.logo {
  //  max-height: 38px;
  //  max-width: 210px;
  //  &:hover {
  //    opacity: 0.8;
  //  }
  //}
  //.logo-text {
  //  color: white;
  //  font-weight: 500;
  //  font-size: 28px;
  //}

.layout-footer {
  //position: fixed;
  //position: absolute;
  //width: 500px;
  //position: relative!important;
}


</style>