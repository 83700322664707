<template>
  <div class="p-d-flex p-ai-center search-input-wrapper">
    <div class="p-input-icon-right">
      <i v-if="modelValue?.length" class="pi pi-times pointer" @click="resetSearch"/>
      <InputText :disabled="disabled" @keyup.enter="searchItems" @input="onInput" class="search-input" :modelValue="modelValue" :placeholder="$t('Search') + '...'" />
    </div>
    <div>
      <Button :disabled="disabled || searchingIsDisabled" icon="pi pi-search" class="search-input-btn" @click="searchItems"></Button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchInput',
  emits: ['update:modelValue', 'show-spinner', 'search-items', 'change-processed-search-data'],
  props: {
    modelValue: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    changeUrl: {
      type: Boolean,
      default: true,
    },
    clearLastSearchingValue: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    clearLastSearchingValue(boolean) {
      if (boolean) {
        this.lastSearchingValue = null
      }
    }
  },
  mounted() {
    if (this.$route.query?.search) this.lastSearchingValue = this.$route.query.search
    // if (this.$route.query.search) {
    //   this.searchData = this.$route.query.search
    //   this.processedSearchValue = this.searchData
    // }
  },
  data() {
    return {
      lastSearchingValue: null
    }
  },
  methods: {
    onInput(event) {
      let trimmedValue = event.target.value?.trim() ?? ''
      if (trimmedValue.length === 0) {
        trimmedValue = null
      }
      this.$emit('update:modelValue', trimmedValue);
    },
    searchItems() {
      if (this.searchingIsDisabled) return
      this.lastSearchingValue = this.modelValue

      if (this.changeUrl) {
        const route = {
          route: this.$route.path,
          query: {
            ...this.$route.query,
            search: this.modelValue,
          }
        }
        this.$router.push(route)
        this.$emit('change-processed-search-data', this.modelValue);
        this.$emit('show-spinner')
      } else {
        // if (!this.modelValue) {
        //   this.resetSearch()
        // } else {
          this.$emit('show-spinner')
          this.$emit('search-items');
        // }
      }
    },
    resetSearch() {
      if (!this.lastSearchingValue) {
        this.$emit('update:modelValue', null);
        return false
      }

      this.$emit('show-spinner')
      this.$emit('change-processed-search-data', null);
      this.lastSearchingValue = null
      this.$emit('update:modelValue', null);


      if (this.changeUrl) {
        if (!this.$route.query.search) return
        const route = {
          route: this.$route.path,
          query: {
            ...this.$route.query,
            // search: null,
          }
        }
        delete route.query.search
        this.$router.push(route)
      } else {
        this.$emit('search-items');
      }
    },
  },
  computed: {
    searchingIsDisabled() {
       return !!((this.changeUrl && this.$route.query.search === this.modelValue)
           || (!this.modelValue && this.lastSearchingValue === null) ||
           (this.modelValue === this.lastSearchingValue))
    },
    isSearchBtnDisabled() {
      // console.log(!this.$route.query.search, !this.modelValue)
      return (!this.$route.query.search && !this.modelValue) ||
          (this.modelValue === this.lastSearchingValue) ||
          this.$route.query.search === this.modelValue
      // return false
    }
  }

}
</script>

<style scoped lang="scss">
.search-input {
  border-radius: 3px 0 0 3px;
  width: auto;
}
.search-input-btn {
  border-radius: 0 3px 3px 0;
}
</style>